import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../contexts/AccountContext';

const Head = () => {
	const { termsContracts } = useContext(AccountContext);
	let navigate = useNavigate();
	return (
		<div className="head">
			<div className="head-left-text">
				<div className="phone" onClick={() => navigate('/atendimento')}>
					<span className="material-symbols-outlined">call</span>
					<span className="atendimento">Atendimento</span>
				</div>
				{/* <div className="email" onClick={() => navigate('/atendimento')}>
					<span className="material-symbols-outlined">mail</span>
					<a href="mailto:comunicacao@objetivoteresina.com.br?subject=Contato Pensar Store">
					comunicacao@objetivoteresina.com.br
					</a>
				</div> */}
			</div>
			<div className="head-right-text">
				<div className="perguntas" onClick={() => navigate('/faq')}>
					<label>F.A.Q.</label>
				</div>
				<div className="termos">
					<label>
						<a target="_blank" rel="noopener noreferrer" href={termsContracts}>
							<h3>Termos & Condições</h3>
						</a>
					</label>
				</div>
			</div>
		</div>
	); 
};

export default Head;
