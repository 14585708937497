function unidadeRetirada(codUnidade) {
    return codUnidade === '1'
        ? 'Proposito Centro'
        : codUnidade === '3'
            ? 'Proposito Leste II'
            : codUnidade === '4'
                ? 'Proposito Centro I'
                : codUnidade === '5'
                    ? 'Proposito Centro II'
                    : codUnidade === '6'
                        ? 'Proposito Leste Infantil'
                        : codUnidade === '7'
                            ? 'Proposito Leste I'
                            : codUnidade === '13'
                                ? 'Great School'
                                : 'Colégio Propósito';
}

function unidadeEndereco(codUnidade) {
    return codUnidade === '1'
        ? 'Av. Frei Serafim, 1989'
        : codUnidade === '3'
            ? 'Rua das Orquídeas, 988'
            : codUnidade === '4'
                ? 'Rua Firmino Pires, 787'
                : codUnidade === '5'
                    ? 'Rua Rui Barbosa, 799'
                    : codUnidade === '6'
                        ? 'Rua das Orquídeas, 191'
                        : codUnidade === '7'
                            ? 'Rua das Orquídeas, 830'
                            : codUnidade === '13'
                                ? 'Av. Nossa Senhora de Fátima, 1000'
                                : 'Rua das Orquídeas, 830';
}

function unidadeTelefone(codUnidade) {
    return codUnidade === '1'
        ? '(86) 3304-2526'
        : codUnidade === '3'
            ? '(86) 3215-5032'
            : codUnidade === '4'
                ? '(86) 3215-5480'
                : codUnidade === '5'
                    ? '(86) 3215-5482'
                    : codUnidade === '6'
                        ? '(86) 3215-5034'
                        : codUnidade === '7'
                            ? '(86) 3025-8551'
                            : codUnidade === '13'
                                ? '(86) 2222-4000'
                                : '(86) 3025-8551';
}

export { unidadeEndereco, unidadeRetirada, unidadeTelefone };